import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { VENUE } from '@tminc/shared/data-models';
import * as fromAdvancePage from './advance-page.reducer';
import * as fromArtists from './artists.reducer';
import * as fromContacts from './contacts.reducer';
import * as fromEvents from './events.reducer';
import * as fromPromoterAdvances from './promoter-advances.reducer';
import * as fromShowProfiles from './show-profiles.reducer';
import * as fromTours from './tours.reducer';
import * as fromVenueProfiles from './venue-profiles.reducer';
import * as fromVenues from './venues.reducer';

export interface State {
    venues: VENUE[];
    tours: fromTours.State[];
    events: fromEvents.State[];
    contacts: fromContacts.State[];
    artists: fromArtists.State[];
    show_profiles: fromShowProfiles.State[];
    venue_profiles: fromVenueProfiles.State[];
    promoter_advances: fromPromoterAdvances.State[];
    advance_page: fromAdvancePage.State;
}

export const reducers: ActionReducerMap<State> = {
    venues: fromVenues.reducer,
    tours: fromTours.reducer,
    contacts: fromContacts.reducer,
    events: fromEvents.reducer,
    artists: fromArtists.reducer,
    show_profiles: fromShowProfiles.reducer,
    venue_profiles: fromVenueProfiles.reducer,
    promoter_advances: fromPromoterAdvances.reducer,
    advance_page: fromAdvancePage.reducer,
};

export function clearState(reducer: ActionReducer<State>): ActionReducer<State> {
    return function (state: State, action: Action): State {
        if (action.type === 'CLEAR_STATE') {
            state = {
                artists: [],
                events: [],
                contacts: [],
                promoter_advances: [],
                show_profiles: [],
                venue_profiles: [],
                tours: [],
                venues: [],
                advance_page: fromAdvancePage.initialState,
            };
        }
        return reducer(state, action);
    };
}

export const metaReducers: MetaReducer<State>[] = [clearState];
