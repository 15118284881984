export const VENUES_RECORD_KEY = 'venues';
export interface StageType {
    name: string;
}
export interface PromoterType {
    name: string;
    email: string;
}

export interface VENUE {
    photo?: string;
    name: string;
    city: string;
    state: string;
    place_id: string;
    address: string;
    phone_number: string;
    google_maps_url: string;
    website: string;
    assigned_to?: string[];
    stages?: StageType[];
    promoters?: PromoterType[];
    updated_at?: Date;
}
