import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { SHOW_PROFILES_RECORD_KEY } from '@tminc/shared/data-models';
import { Store } from '@ngrx/store';

import { LoadShowProfiles } from '@rootStore/actions/show-profiles.actions';
import { trace } from '@angular/fire/performance';
import { AngularFireAuth } from '@angular/fire/auth';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ShowProfilesService {
    constructor(private db: AngularFirestore, private auth: AngularFireAuth, private store: Store<any>) {}

    updateData(org_id: string) {
        this.auth.authState
            .pipe(
                switchMap((authData) => {
                    return authData
                        ? this.db
                              .collection(SHOW_PROFILES_RECORD_KEY, (ref) => ref.where('org_id', '==', org_id))
                              .valueChanges()
                        : of([]);
                }),
                trace('get_show_profiles'),
            )
            .subscribe((data) => {
                this.store.dispatch(new LoadShowProfiles(data));
            });
    }
}
