import { PersonnelType, ContactValue } from './contacts.interfaces';
import { ContentComponents } from '@tminc/shared/data-models';
import { ConvertStringListToFormlySelectOptions } from '@formly/formly-helpers';

const CommonContactOptions = [
    'Schedule',
    'Safety',
    'Security',
    'Merch',
    ContentComponents.MEET_GREET,
    'Parking',
    'Stage',
    'Audio',
    'Lighting',
    'Video',
    ContentComponents.SFX,
    'Hospitality',
    'Dressing Rooms',
    'Box Office',
    'Settlement',
];
export const VenueContactOptions = [ContactValue.ADV, ...CommonContactOptions];

const TourContactOptions = [ContactValue.ADV, ContactValue.DOS, ...CommonContactOptions];

const PersonnelTypeOptions = ['Artist', 'Crew', 'Management', 'Support'];
export const VenuePersonnelTypeOptions = ['Local Crew', 'Venue Staff', 'Promoter'];

export const getContactSelectOptions = (personnelType: PersonnelType) => {
    switch (personnelType) {
        case PersonnelType.TOUR_CONACT:
            return ConvertStringListToFormlySelectOptions(TourContactOptions);
        case PersonnelType.TOUR_PERSONNEL_TYPE:
            return ConvertStringListToFormlySelectOptions(PersonnelTypeOptions);
        case PersonnelType.VENUE_PERSONNEL_TYPE:
            return ConvertStringListToFormlySelectOptions(VenuePersonnelTypeOptions);
        case PersonnelType.VENUE_PERSONNEL_CONTACT:
            return ConvertStringListToFormlySelectOptions(VenueContactOptions);
    }
};
