export enum ContentComponents {
    HOME = 'home',
    INTRODUCTION = 'introduction',
    BACKSTAGE = 'backstage',
    PERSONNEL = 'personnel',
    SCHEDULE = 'schedule',
    PRODUCTION = 'production',
    PARKING = 'parking',
    STAGE = 'stage',
    AUDIO = 'audio',
    LIGHTING = 'lighting',
    VIDEO = 'video',
    SFX = 'SFX',
    MEET_GREET = 'Meet & Greet',
    MERCH = 'merch',
    SECURITY = 'security',
    SAFETY = 'safety',
    HOSPITALITY = 'hospitality',
    DRESSING_ROOMS = 'dressing rooms',
    MISC = 'misc',
    BOX_OFFICE = 'box office',
    SETTLEMENT = 'settlement',
    SUPPORT = 'support',
    TOUR_DAYSHEET = 'tour daysheet',
    VENUE_DAYSHEET = 'venue daysheet',
    DOCS = 'docs',
    GUESTLIST = 'guestlist',
}
