import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { trace } from '@angular/fire/performance';
import { Store } from '@ngrx/store';
import { LoadPromoterAdvances } from '@rootStore/actions/promoter-advances.actions';
import { UserRoles } from '@tminc/data-access-user';
import { PROMOTER_ADVANCES_RECORD_KEY, User, VENUE } from '@tminc/shared/data-models';
import * as dayjs from 'dayjs';
import isEqual from 'lodash-es/isEqual';
import isEmpty from 'lodash-es/isEmpty';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { VenuesService } from './venues.service';

@Injectable({
    providedIn: 'root',
})
export class PromoterAdvancesService {
    hidePastEvents$ = new BehaviorSubject<boolean>(true);
    constructor(
        private db: AngularFirestore,
        private auth: AngularFireAuth,
        private store: Store<any>,
        private venuesService: VenuesService,
    ) {}

    updateData(user: User) {
        const seven_days_ago = dayjs().subtract(7, 'day');

        this.auth.authState
            .pipe(
                switchMap((authData) => {
                    const tm_advances$ = this.db
                        .collection(PROMOTER_ADVANCES_RECORD_KEY, (ref) =>
                            ref.where('assigned_to', 'array-contains', user.id),
                        )
                        .valueChanges();

                    const venue_advances$ = combineLatest([
                        this.venuesService.selectedVenue$,
                        this.hidePastEvents$,
                    ]).pipe(
                        filter(([venue, hidePastEvents]) => !isEmpty(venue)),
                        switchMap(([venue, hidePastEvents]: [VENUE, boolean]) => {
                            return this.db
                                .collection(PROMOTER_ADVANCES_RECORD_KEY, (ref) => {
                                    // TODO: This should be venue_id but we didn't have the info saved in the advance. After some time replace venue_address with venue_id
                                    let query = ref.where('venue_address', '==', venue.address);
                                    if (hidePastEvents) {
                                        query = query.where('event_date', '>=', seven_days_ago.toDate());
                                    }
                                    return query;
                                })
                                .valueChanges();
                        }),
                    );

                    const advances$ = user.roles.includes(UserRoles.Promoter) ? venue_advances$ : tm_advances$;
                    return authData?.email?.length ? advances$ : of([]);
                }),
                trace('get_promoter_advances'),
                distinctUntilChanged((curr, prev) => isEqual(curr, prev)),
            )
            .subscribe((data) => {
                this.store.dispatch(new LoadPromoterAdvances(data));
            });
    }
}
