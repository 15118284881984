import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromTours from '../reducers/tours.reducer';

export const selectToursState = createFeatureSelector<fromTours.State>('tours');

export const selectToursByArtist = artistId =>
  createSelector(
    selectToursState,
    (tours: any) => {
      return tours.filter(tour => tour.artistId === artistId);
    }
  );
