import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromArtists from '../reducers/artists.reducer';
import { ARTISTS_RECORD_KEY } from '@tminc/shared/data-models';
import find from 'lodash-es/find';

import { NO_TOUR_SUPPORT } from '@tminc/shared/data-models';
import { removeUndefinedValues } from '@tminc/shared/util-helpers';

export const selectArtistsState = createFeatureSelector<fromArtists.State>(ARTISTS_RECORD_KEY);

export const selectArtists = createSelector(selectArtistsState, (artists: any) => {
    return artists || [];
});

export const selectArtistById = (artistId) =>
    createSelector(selectArtistsState, (artists: any) => {
        return find(artists, ['uid', artistId]);
    });

export const selectSupportArtists = createSelector(selectArtistsState, (artists: any) => {
    return [
        {
            name: NO_TOUR_SUPPORT,
        },
        ...artists.map((artist) => {
            const data = {
                name: artist.name,
                advance_img: artist.advance_img,
                photo: artist.photo,
                logo: artist.logo,
            };
            return removeUndefinedValues(data);
        }),
    ];
});
