import { ShowProfilesActionTypes, ShowProfilesActions } from '../actions/show-profiles.actions';

export interface State {
    [key: string]: any;
}

export const initialState: State[] = [];

export function reducer(state = initialState, action: ShowProfilesActions): State[] {
    switch (action.type) {
        case ShowProfilesActionTypes.LoadShowProfiles:
            return [...action.payload];
        default:
            return state;
    }
}
