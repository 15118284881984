import { FormlyFieldConfig, FormlyTemplateOptions } from '@ngx-formly/core';
import startCase from 'lodash-es/startCase';
import { FormlyAutocompleteType } from './common/autocomplete';
export interface FormlyOptionsType {
    value: string;
    label: string;
}

export const ConvertStringListToFormlySelectOptions = (list, title_case = false) => {
    return list.map((item) => ({ value: item, label: title_case ? startCase(item) : item }));
};
export const ConvertObjectsToFormlySelectOptions = (array: any[] = [], key: string): FormlyOptionsType[] => {
    return array.map((obj) => ({
        label: obj[key],
        value: obj[key],
    }));
};

export const formlyRow = (fieldConfig: FormlyFieldConfig[], additional_classes: string = '') => {
    return {
        fieldGroupClassName: 'display-flex ' + additional_classes,
        fieldGroup: fieldConfig,
    };
};

export const formlyCol = (fieldConfig: FormlyFieldConfig[]) => {
    return {
        fieldGroupClassName: 'display-flex-col',
        fieldGroup: fieldConfig,
    };
};

export interface formlyConfig extends FormlyFieldConfig {
    key: string;
    className?: string;
    label?: string;
    templateOptions?: FormlyTemplateOptions;
    expressionProperties?: any;
    modelOptions?: any;
}

const StartCase = (str: string) => {
    return startCase(str.replace(/_/g, ' '));
};

export const formlyInput = (config: formlyConfig): FormlyFieldConfig => {
    return {
        type: 'input',
        key: config.key,
        className: 'formly-field ' + (config.className || ''),
        templateOptions: {
            appearance: 'outline',
            label: config.label || StartCase(config.key),
            ...config.templateOptions,
        },
        ...(config.expressionProperties ? { expressionProperties: config.expressionProperties } : {}),
        validators: {
            ...config.validators,
        },
        validation: {
            show: true,
        },
        hooks: {
            ...config.hooks,
        },
    };
};
export const formlyAutocomplete = (config: formlyConfig): FormlyFieldConfig => {
    return {
        type: FormlyAutocompleteType,
        key: config.key,
        className: 'formly-field ' + (config.className || ''),
        templateOptions: {
            appearance: 'outline',
            label: config.label || StartCase(config.key),
            ...config.templateOptions,
        },
        ...(config.expressionProperties ? { expressionProperties: config.expressionProperties } : {}),
        validators: {
            ...config.validators,
        },
        validation: {
            show: true,
        },
        hooks: {
            ...config.hooks,
        },
    };
};

export const formlyDatePicker = (config: formlyConfig): FormlyFieldConfig => {
    return {
        type: 'datepicker',
        key: config.key,
        className: 'formly-field ' + (config.className || ''),
        templateOptions: {
            appearance: 'outline',
            label: config.label || StartCase(config.key),
            ...config.templateOptions,
        },
    };
};

export const formlyChipsAutoComplete = (config: formlyConfig): FormlyFieldConfig => {
    return {
        key: config.key,
        type: 'chips',
        className: 'formly-field ' + (config.className || ''),
        templateOptions: {
            appearance: 'outline',
            // API Note
            // filter: ['None'],
            label: config.label || StartCase(config.key),
            ...config.templateOptions,
        },
    };
};

export const formlySelect = (config: formlyConfig): FormlyFieldConfig => {
    return {
        type: 'select',
        key: config.key,
        className: 'formly-field ' + (config.className || ''),
        templateOptions: {
            appearance: 'outline',
            label: config.label || StartCase(config.key),
            ...config.templateOptions,
        },
        validation: {
            show: true,
        },
        hooks: {
            ...config.hooks,
        },
    };
};

export const formlyCheckbox = (config: formlyConfig): FormlyFieldConfig => {
    return {
        type: 'checkbox',
        key: config.key,
        className: 'formly-field ' + (config.className || ''),
        templateOptions: {
            indeterminate: false,
            label: config.label || StartCase(config.key),
            ...config.templateOptions,
        },
    };
};

export const formlyRadio = (config: formlyConfig): FormlyFieldConfig => {
    return {
        type: 'radio',
        key: config.key,
        className: 'formly-field ' + (config.className || ''),
        templateOptions: {
            appearance: 'outline',
            label: config.label || StartCase(config.key),
            ...config.templateOptions,
        },
    };
};
export const formlyTextArea = (config: formlyConfig): FormlyFieldConfig => {
    return {
        type: 'textarea',
        key: config.key,
        className: 'formly-field ' + (config.className || ''),
        templateOptions: {
            appearance: 'outline',
            label: config.label || StartCase(config.key),
            ...config.templateOptions,
        },
    };
};

export const TextArea = (key: string, props?, prefix?: string, className?: string) => {
    return formlyTextArea({
        key: prefix ? `${prefix}_${key}` : key,
        className,
        templateOptions: {
            label: startCase(key),
            autosize: true,
            autosizeMinRows: 1,
            appearance: 'outline',
            ...props,
        },
    });
};
export const TextArea2 = (config: {
    key: string;
    prefix?: string;
    className?: string;
    templateOptions?: {
        [key: string]: string | boolean;
    };
}) => {
    return formlyTextArea({
        key: config.prefix ? `${config.prefix}_${config.key}` : config.key,
        className: config.className || '',
        templateOptions: {
            label: startCase(config.key),
            autosize: true,
            autosizeMinRows: 1,
            appearance: 'outline',
            ...config.templateOptions,
        },
    });
};

export const CarriedRequestedTextArea = (key, prefix, props?) => {
    return [TextArea(`carried_${key}`, props, prefix), TextArea(`requested_${key}`, {}, prefix)];
};
export const CarriedRequestedSuffixTextArea = (key, prefix, props?) => {
    return [
        TextArea(`${key}_carried`, props ? { ...props, label: props.label + ' Carried' } : {}, prefix),
        TextArea(`${key}_requested`, props ? { ...props, label: props.label + ' Requested' } : {}, prefix),
    ];
};

export const VenueConfirmCheckbox = (config: formlyConfig) => {
    return formlyCheckbox({
        key: config.key,
        className: config.className || '',
        templateOptions: {
            label: config.label || 'Confirm',
        },
    });
};

export const VenueConfirmRadio = (config: formlyConfig): FormlyFieldConfig => {
    return {
        type: 'radio',
        key: config.key,
        className: 'formly-field ' + config.className || '',
        templateOptions: {
            required: true,
            // placeholder: 'Confirm / Deny',
            label: config.label || StartCase(config.key),
            ...config.templateOptions,
        },
    };
};

export const CarriedRequestedCheckbox = (key, prefix) => {
    return [
        TextArea(`carried_${key}`, { disabled: true }, prefix),
        TextArea(`requested_${key}`, { disabled: true }, prefix),
        VenueConfirmCheckbox({
            key: prefix ? `${prefix}_${key}_confirmed` : `${key}_confirmed`,
        }),
    ];
};
export const RequestedSuffixConfirmed = (key, prefix) => {
    return [
        TextArea(`${key}_requested`, { disabled: true }, prefix),
        TextArea(`${key}_confirm_note`, { label: 'Confirm Note' }, prefix),
        VenueConfirmCheckbox({
            key: prefix ? `${prefix}_${key}_confirmed` : `${key}_confirmed`,
        }),
    ];
};

export const AutocompleteFilter = (items: string[], text: string) => {
    return items.filter((item) => item.toLowerCase().indexOf(text.toLowerCase()) === 0);
};
