import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { trace } from '@angular/fire/performance';
import { Store } from '@ngrx/store';
import { LoadEvents } from '@rootStore/actions/events.actions';
import { UserRoles } from '@tminc/data-access-user';
import { EVENT, EVENTS_RECORD_KEY, User, VENUE } from '@tminc/shared/data-models';
import * as dayjs from 'dayjs';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { VenuesService } from './venues.service';

@Injectable({
    providedIn: 'root',
})
export class EventsService {
    hidePastEvents$ = new BehaviorSubject<boolean>(true);
    constructor(
        private db: AngularFirestore,
        private auth: AngularFireAuth,
        private store: Store<any>,
        private venuesService: VenuesService,
    ) {}

    updateData(user: User) {
        const yesterday = dayjs().subtract(1, 'day');
        const seven_days_ago = dayjs().subtract(7, 'day');

        this.auth.authState
            .pipe(
                switchMap((authData) => {
                    const tm_events$ = this.db
                        .collection(EVENTS_RECORD_KEY, (ref) =>
                            ref
                                .where('org_id', '==', user.org_id)
                                .where('assigned_to', 'array-contains', user.id)
                                .where('date', '>=', yesterday.toDate()),
                        )
                        .valueChanges();
                    const venue_events$ = combineLatest([this.venuesService.selectedVenue$, this.hidePastEvents$]).pipe(
                        filter(([venue, hidePastEvents]) => !isEmpty(venue)),
                        switchMap(([venue, hidePastEvents]: [VENUE, boolean]) => {
                            return this.db
                                .collection<EVENT>(EVENTS_RECORD_KEY, (ref) => {
                                    let query = ref.where('venue_id', '==', venue.place_id);
                                    if (hidePastEvents) {
                                        query = query.where('date', '>=', seven_days_ago.toDate());
                                    }
                                    return query;
                                })
                                .valueChanges();
                        }),
                    );

                    const events$ = user.roles.includes(UserRoles.Promoter) ? venue_events$ : tm_events$;

                    return authData?.email?.length ? events$ : of([]);
                }),
                trace('get_events'),
                distinctUntilChanged((curr, prev) => isEqual(curr, prev)),
            )
            .subscribe((data) => {
                this.store.dispatch(new LoadEvents(data));
            });
    }

    togglePastEvents() {
        this.hidePastEvents$.next(!this.hidePastEvents$.value);
    }
}
