import { ContactsActions, ContactsActionTypes } from '../actions/contacts.actions';

export interface State {
    name: string;
    updated_at: Date;
    email: Date;
    phone?: string;
    organization: string;
    type: string;
    venue_id: string;
    uid: string;
}

export const initialState: State[] = [];

export function reducer(state = initialState, action: ContactsActions): State[] {
    switch (action.type) {
        case ContactsActionTypes.LoadContacts:
            return [...action.payload];
        default:
            return state;
    }
}
