import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule } from '@angular/forms';

import { LoginComponent } from './login.component';
import { ROUTING } from './login.routing';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, ROUTING],
    declarations: [LoginComponent],
})
export class FeatureLoginModule {}
