import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { trace } from '@angular/fire/performance';
import { Store } from '@ngrx/store';
import { LoadVenueProfiles } from '@rootStore/actions/venue-profiles.actions';
import { VENUE, VENUE_PROFILES_RECORD_KEY } from '@tminc/shared/data-models';
import isEqual from 'lodash-es/isEqual';
import isEmpty from 'lodash-es/isEmpty';
import sortBy from 'lodash-es/sortBy';
import { of } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { VenuesService } from './venues.service';

@Injectable({
    providedIn: 'root',
})
export class VenueProfilesService {
    constructor(private db: AngularFirestore, private store: Store<any>, private venuesService: VenuesService) {
        this.venuesService.selectedVenue$
            .pipe(
                filter((venue) => !isEmpty(venue)),
                switchMap((venue: VENUE) => {
                    return venue
                        ? this.db
                              .collection(VENUE_PROFILES_RECORD_KEY, (ref) =>
                                  ref.where('venue_id', '==', venue.place_id),
                              )
                              .valueChanges()
                              .pipe(
                                  map((profiles) => {
                                      return sortBy(profiles, ['type']);
                                  }),
                                  trace('get_venue_profiles'),
                                  shareReplay({
                                      bufferSize: 1,
                                      refCount: true,
                                  }),
                              )
                        : of([]);
                }),
                trace('get_venue_profiles'),
                distinctUntilChanged((curr, prev) => isEqual(curr, prev)),
            )
            .subscribe((data) => {
                this.store.dispatch(new LoadVenueProfiles(data));
            });
    }
}
