import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { TOURS_RECORD_KEY } from '@tminc/shared/data-models';
import { Store } from '@ngrx/store';

import { LoadTours } from '@rootStore/actions/tours.actions';
import { trace } from '@angular/fire/performance';
import { switchMap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ToursService {
    constructor(private db: AngularFirestore, private auth: AngularFireAuth, private store: Store<any>) {}

    updateData(org_id: string) {
        this.auth.authState
            .pipe(
                switchMap((authData) => {
                    return authData
                        ? this.db
                              .collection(TOURS_RECORD_KEY, (ref) =>
                                  ref.where('org_id', '==', org_id).where('archived', '!=', true),
                              )
                              .valueChanges()
                        : of([]);
                }),
                trace('get_tours'),
            )
            .subscribe((data) => {
                this.store.dispatch(new LoadTours(data));
            });
    }
}
