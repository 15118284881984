import {
  PromoterAdvancesActionTypes,
  PromoterAdvancesActions
} from '../actions/promoter-advances.actions';

export interface State {
  [key: string]: any;
}

export const initialState: State[] = [];

export function reducer(
  state = initialState,
  action: PromoterAdvancesActions
): State[] {
  switch (action.type) {
    case PromoterAdvancesActionTypes.LoadPromoterAdvances:
      return [...action.payload];
    default:
      return state;
  }
}
