import { Routes } from '@angular/router';
import { AuthGuard } from '@common/guards';

export const ROUTES: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/login',
    },
    {
        path: 'login',
        loadChildren: () => import('@tminc/feature-login').then((m) => m.FeatureLoginModule),
    },
    {
        path: 'advance',
        loadChildren: () => import('./views/advance/advance.module').then((m) => m.AdvanceModule),
    },
    {
        path: 'day-sheet',
        loadChildren: () => import('./views/day-sheet/day-sheet.module').then((m) => m.DaySheetModule),
    },
    {
        path: 'venue-day-sheet',
        loadChildren: () => import('./views/venue-day-sheet/venue-day-sheet.module').then((m) => m.VenueDaySheetModule),
    },
    {
        path: 'guest-list/new/:event_id',
        loadChildren: () => import('./views/guest-list/new-guest').then((m) => m.NewGuestModule),
    },
    {
        path: 'tm-portal',
        loadChildren: () => import('./views/tm-portal/tm-portal.module').then((m) => m.TmPortalModule),
    },
    {
        path: '',
        loadChildren: () => import('./views/shell/shell.module').then((m) => m.ShellModule),
        canActivate: [AuthGuard],
    },
    { path: '**', pathMatch: 'full', redirectTo: '/login' },
];
