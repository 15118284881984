import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromShowProfiles from '../reducers/show-profiles.reducer';
import { SHOW_PROFILES_RECORD_KEY } from '@tminc/shared/data-models';

export const selectShowProfilesState = createFeatureSelector<fromShowProfiles.State>(SHOW_PROFILES_RECORD_KEY);

export const selectShowProfiles = (artistId) =>
    createSelector(selectShowProfilesState, (show_profiles: any) => {
        return show_profiles.filter((show_profile) => show_profile.artistId === artistId);
    });
