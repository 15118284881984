import { EventsActions, EventsActionTypes } from '../actions/events.actions';

export interface State {
  city: string;
  createdAt: Date;
  date: Date;
  notes: string;
  productionContact: string;
  productionEmail: string;
  state: string;
  tour: string;
  tourId: string;
  uid: string;
  venue: string;
}

export const initialState: State[] = [];

export function reducer(state = initialState, action: EventsActions): State[] {
  switch (action.type) {
    case EventsActionTypes.LoadEvents:
      return [...action.payload];
    default:
      return state;
  }
}
