import { Action } from '@ngrx/store';

export enum PromoterAdvancesActionTypes {
  LoadPromoterAdvances = '[PromoterAdvances] Load Promoter Advances'
}

export class LoadPromoterAdvances implements Action {
  readonly type = PromoterAdvancesActionTypes.LoadPromoterAdvances;
  constructor(public payload: any) {}
}

export type PromoterAdvancesActions = LoadPromoterAdvances;
