import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

import { SoftwareUpdateDialogComponent } from './software-update-dialog.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    imports: [CommonModule, MatDialogModule, MatButtonModule],
    declarations: [SoftwareUpdateDialogComponent],
    exports: [SoftwareUpdateDialogComponent],
})
export class SoftwareUpdateDialogModule {}
