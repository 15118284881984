import { Action } from '@ngrx/store';

export enum ToursActionTypes {
    LoadTours = '[Tours] Load Tours',
}

export class LoadTours implements Action {
    readonly type = ToursActionTypes.LoadTours;
    constructor(public payload: any) {}
}

export type ToursActions = LoadTours;
