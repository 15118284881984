import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';

import { ROUTES } from './app.routing';
import { environment } from '../environments/environment';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthGuard } from '@common/guards';
import { SoftwareUpdateDialogModule } from '@common/components/sofware-update-dialog';
import { UserService } from './services';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { EmailValidator, EmailValidatorMessage } from '@formly/formly-custom-validators';
import {
    AngularFireAnalyticsModule,
    ScreenTrackingService,
    APP_NAME,
    APP_VERSION,
    UserTrackingService,
} from '@angular/fire/analytics';
import { AngularFirePerformanceModule, PerformanceMonitoringService } from '@angular/fire/performance';
import { USER_SERVICE_TOKEN } from '@tminc/data-access-user';
import { LOGIN_TITLE_TOKEN } from '@tminc/feature-login';
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/auth';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR, SETTINGS as FIRESTORE_SETTINGS } from '@angular/fire/firestore';
import { dataCyExtension } from '@tminc/util-environment';
import { DEFAULT_SNACKBAR_OPTIONS_PROVIDER } from './config/mat-snack-bar.config';
import { DatePipe } from '@angular/common';

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        LayoutModule,
        RouterModule.forRoot(ROUTES, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' }),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireStorageModule,
        AngularFireFunctionsModule,
        AngularFireAnalyticsModule,
        AngularFirePerformanceModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        EffectsModule.forRoot([]),
        // Instrumentation must be imported after importing StoreModule (config is optional)
        !environment.production
            ? StoreDevtoolsModule.instrument({
                  maxAge: 25, // Retains last 25 states
                  logOnly: environment.production, // Restrict extension to log-only mode
              })
            : [],
        MatSnackBarModule,
        ClipboardModule,
        // HotkeysDialogModule,
        ServiceWorkerModule.register('./ngsw-worker.js', {
            enabled: environment.production,
            registrationStrategy: 'registerImmediately',
        }),
        SoftwareUpdateDialogModule,
        FormlyMaterialModule,
        FormlyModule.forRoot({
            extras: { lazyRender: true },
            validators: [{ name: 'email', validation: EmailValidator }],
            validationMessages: [
                { name: 'email', message: EmailValidatorMessage },
                { name: 'required', message: 'Required' },
            ],
            extensions: [
                {
                    name: 'data-cy-extension',
                    extension: dataCyExtension,
                },
            ],
        }),
    ],
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    providers: [
        AuthGuard,
        UserService,
        { provide: USER_SERVICE_TOKEN, useExisting: UserService },
        UserTrackingService,
        ScreenTrackingService,
        PerformanceMonitoringService,
        { provide: APP_VERSION, useValue: '0.8.0' },
        { provide: APP_NAME, useValue: 'All Access' },
        { provide: LOGIN_TITLE_TOKEN, useValue: 'Welcome' },
        { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9099] : undefined },
        { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8081] : undefined },
        {
            provide: FIRESTORE_SETTINGS,
            useValue: environment.useEmulators
                ? { host: 'localhost:8081', ssl: false, experimentalAutoDetectLongPolling: true }
                : {},
        },
        DEFAULT_SNACKBAR_OPTIONS_PROVIDER,
        DatePipe, // used by title service
    ],
})
export class AppModule {}
