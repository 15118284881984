import { Action } from '@ngrx/store';

export enum AdvancePageActionTypes {
    SetUserType = '[Advance Page] Set User Type',
    SetUserRecord = '[Advance Page] Set User Record',
    SetFirebaseUser = '[Advance Page] Set Firebase User',
}

export class SetUserType implements Action {
    readonly type = AdvancePageActionTypes.SetUserType;
    constructor(public payload: any) {}
}
export class SetUserRecord implements Action {
    readonly type = AdvancePageActionTypes.SetUserRecord;
    constructor(public payload: any) {}
}
export class SetFirebaseUser implements Action {
    readonly type = AdvancePageActionTypes.SetFirebaseUser;
    constructor(public payload: any) {}
}

export type AdvancePageActions = SetUserType | SetUserRecord | SetFirebaseUser;
