import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface UserServiceInterface {
    login(credentials: { email: string; password: string }): void;
    logout(): void;
    user$: Observable<any>;
}

export const USER_SERVICE_TOKEN = new InjectionToken<UserServiceInterface>('User Interface Token');
