export const USERS_RECORD_KEY = 'users';
export interface User {
    email: string;
    id: string;
    roles: string[];
    org_id: string;
    name: string;
    sendgrid?: {
        advance_template?: string;
        completed_template?: string;
    };
}
