import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { trace } from '@angular/fire/performance';
import { Store } from '@ngrx/store';
import { LoadVenues } from '@rootStore/actions/venues.actions';
import { VENUE, VENUES_RECORD_KEY } from '@tminc/shared/data-models';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class VenuesService {
    selectedVenue$ = new BehaviorSubject<VENUE | null>(null);

    constructor(private db: AngularFirestore, private auth: AngularFireAuth, private store: Store<any>) {}

    updateData(userId) {
        this.auth.authState
            .pipe(
                switchMap((authData) => {
                    return authData
                        ? this.db
                              .collection(VENUES_RECORD_KEY, (ref) =>
                                  ref.where('assigned_to', 'array-contains', userId),
                              )
                              .valueChanges()
                        : of([]);
                }),
                trace('get_venues'),
                filter((data) => !isEmpty(data)),
                distinctUntilChanged((curr, prev) => isEqual(curr, prev)),
            )
            .subscribe((data) => {
                this.store.dispatch(new LoadVenues(data));
            });
    }
}
