import { ARTIST } from './artists.model';
import { ContentComponents } from './content-components.interface';
import { EVENT } from './events.model';
import startCase from 'lodash-es/startCase';

export interface AdvanceParamsType {
    artist: ARTIST;
    event: EVENT;
    section?: ContentComponents;
}
export enum SideNavType {
    HEADER = 'header',
    ROUTE = 'route',
    LINK = 'link',
    COMING_SOON = 'coming_soon',
}

export class SideNav {
    public type: SideNavType;
    public advanced: boolean;
    public key: string;
    public label: string;
    public ping: number;
    public promoter_approved: number;
    public promoter_denied: number;
    public promoter_pending: number;
    public all_requests_tm_approved: boolean;

    constructor(config: { key: string; label?: string; advanced?: any; type?: SideNavType }) {
        this.key = config.key;
        this.label = config.label || startCase(this.key);
        this.advanced = config.advanced || false;
        this.type = config.type || SideNavType.ROUTE;
    }
}
