import { Action } from '@ngrx/store';

export enum ContactsActionTypes {
    LoadContacts = '[Contacts] Load Contacts',
}

export class LoadContacts implements Action {
    readonly type = ContactsActionTypes.LoadContacts;
    constructor(public payload: any) {}
}

export type ContactsActions = LoadContacts;
