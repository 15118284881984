import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromPromoterAdvances from '../reducers/promoter-advances.reducer';
import { selectArtistsState } from './artists.selectors';
import { selectEventsState } from './events.selectors';
import find from 'lodash-es/find';
import pick from 'lodash-es/pick';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import omit from 'lodash-es/isEmpty';

const propsToOmit = ['createdAt', 'userId'];

export const selectPromoterAdvancesState = createFeatureSelector<fromPromoterAdvances.State>('promoter_advances');

export const selectPromoterAdvances = createSelector(
    selectPromoterAdvancesState,
    selectArtistsState,
    selectEventsState,
    (promoterAdvances: any, artists, events) => {
        if (isEmpty(promoterAdvances) || isEmpty(artists) || isEmpty(events)) {
            return {};
        }
        const data = promoterAdvances.map((advance) => {
            const event = pick(find(events, ['uid', advance.eventId]), ['city', 'state', 'tour', 'venue', 'date']);
            const artist = find(artists, ['uid', advance.artistId]);
            const promoter_advance = omit(
                {
                    ...event,
                    artist: get(artist, 'name'),
                    ...advance,
                    uid: advance.artistId + '_' + advance.eventId,
                },
                propsToOmit,
            );
            return promoter_advance;
        });
        return data;
    },
);

export const selectPromoterAdvanceByUid = (uid) =>
    createSelector(selectPromoterAdvances, (advances) => {
        return find(advances, ['uid', uid]);
    });
