import { VENUE } from '@tminc/shared/data-models';
import { VenuesActions, VenuesActionTypes } from '@rootStore/actions/venues.actions';

export const initialState: VENUE[] = [];

export function reducer(state = initialState, action: VenuesActions): VENUE[] {
    switch (action.type) {
        case VenuesActionTypes.LoadVenues:
            return [...action.payload];
        default:
            return state;
    }
}
