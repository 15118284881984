import { VenueProfilesActionTypes, VenueProfilesActions } from '../actions/venue-profiles.actions';

export interface State {
    [key: string]: any;
}

export const initialState: State[] = [];

export function reducer(state = initialState, action: VenueProfilesActions): State[] {
    switch (action.type) {
        case VenueProfilesActionTypes.LoadVenueProfiles:
            return [...action.payload];
        default:
            return state;
    }
}
