export enum PersonnelType {
    TOUR_CONACT = 'tour_contact',
    TOUR_PERSONNEL_TYPE = 'tour_personnel_type',
    VENUE_PERSONNEL_TYPE = 'venue_personnel_type',
    VENUE_PERSONNEL_CONTACT = 'venue_personnel_contact',
}

export enum ContactValue {
    ADV = 'ADV',
    DOS = 'DOS',
}

export enum ContactTitleType {
    SELECT = 'select',
    INPUT = 'input',
}
export interface ContactConfig {
    personnelType: PersonnelType;
    personnelContact: PersonnelType;
    templateOptions?: { [name: string]: any };
    contacts_list?: any;
}

export interface ContactsConfig {
    key: string;
    contactConfig: ContactConfig;
    addText: string;
}
