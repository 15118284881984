import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserRoles } from '@tminc/data-access-user';
import { User, USERS_RECORD_KEY } from '@tminc/shared/data-models';
import { getObservableSnapshot } from '@tminc/shared/util-helpers';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { ArtistsService } from './artists.service';
import { ContactsService } from './contacts.service';
import { EventsService } from './events.service';
import { PromoterAdvancesService } from './promoter-advances.service';
import { ShowProfilesService } from './show-profiles.service';
import { ToursService } from './tours.service';
import { VenueProfilesService } from './venue-profiles.service';
import { VenuesService } from './venues.service';

@Injectable()
export class UserService {
    userId;
    private destroy$ = new Subject();
    private user: ReplaySubject<User> = new ReplaySubject(1);
    user$: Observable<User>;
    constructor(
        private auth: AngularFireAuth,
        private router: Router,
        private artistsService: ArtistsService,
        private showProfilesService: ShowProfilesService,
        private eventsService: EventsService,
        private toursService: ToursService,
        private venuesService: VenuesService,
        private promoterAdvancesService: PromoterAdvancesService,
        private contactsService: ContactsService,
        public venueProfilesService: VenueProfilesService, // Used here to initalize service
        private store: Store<any>,
        private db: AngularFirestore,
    ) {
        this.auth.authState
            .pipe(
                filter((authData) => !!authData),
                takeUntil(this.destroy$),
            )
            .subscribe(async (authData: firebase.User) => {
                const user: User = await this.getUserRecord(authData.uid);
                // Prevent call on tm_portal users
                if (user) {
                    this.getData(user);
                }
            });
        this.user$ = this.user.asObservable().pipe(distinctUntilChanged());
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private async getUserRecord(user_id) {
        const user: User = await getObservableSnapshot(this.db.doc(`${USERS_RECORD_KEY}/${user_id}`).valueChanges());
        this.user.next(user);
        return user;
    }

    private async getData(user) {
        if (user.roles.includes(UserRoles.Promoter)) {
            this.venuesService.updateData(user.id);
        }

        if (user.roles.includes(UserRoles.TM)) {
            this.toursService.updateData(user.org_id);
            this.showProfilesService.updateData(user.org_id);
            this.artistsService.updateData(user.id);
        }

        this.promoterAdvancesService.updateData(user);
        this.eventsService.updateData(user);
        this.contactsService.updateData(user);
    }

    login(auth) {
        this.auth
            .signInWithEmailAndPassword(auth.email, auth.password)
            .then(async (res) => {
                const user: User = await this.getUserRecord(res?.user?.uid);
                user.roles.includes(UserRoles.Promoter)
                    ? this.router.navigate(['venues'])
                    : this.router.navigate(['artists']);
            })
            .catch((error) => {
                alert(error.message);
            });
    }

    logout() {
        this.auth.signOut().then((res) => {
            this.venuesService.selectedVenue$.next(null);
            this.user.next(undefined);
            // Reset store data
            this.store.dispatch({ type: 'CLEAR_STATE' });
            this.router.navigate(['/']);
            localStorage.removeItem('selected_artist');
        });
    }
}
