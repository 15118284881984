import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormControl, ValidationErrors, Validators } from '@angular/forms';

export function EmailValidator(control: FormControl): ValidationErrors | null {
    return !control.value || /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(control.value)
        ? null
        : { email: true };
}
export function EmailValidatorMessage(err, field: FormlyFieldConfig) {
    // return `"${field.formControl?.value}" is not a valid IP Address`;
    return `Invalid Email`;
}

// export function TimeValidator(control: FormControl): ValidationErrors | null {
//     //mkyong.com/regular-expressions/how-to-validate-time-in-12-hours-format-with-regular-expression/
//     return !control.value || /^(1[012]|0[1-9]):[0-5][0-9] (AM|PM)$/.test(control.value) ? null : { time: true };
// }
// export function TimeValidatorMessage(err, field: FormlyFieldConfig) {
//     // return `"${field.formControl?.value}" is not a valid IP Address`;
//     return `12-hour format required (XX:XX AM/PM)`;
// }
