import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { UserServiceInterface, USER_SERVICE_TOKEN } from '@tminc/data-access-user';
import { LOGIN_TITLE_TOKEN } from './login.tokens';

@Component({
    templateUrl: './login.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
    loginForm = this.fb.group({
        email: ['', Validators.required],
        password: [''],
    });

    constructor(
        private fb: FormBuilder,
        @Inject(USER_SERVICE_TOKEN) private userService: UserServiceInterface,
        @Inject(LOGIN_TITLE_TOKEN) public title: string,
    ) {}

    login() {
        this.userService.login(this.loginForm.value);
    }

    logout() {
        this.userService.logout();
    }
}
