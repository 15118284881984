export const ARTISTS_RECORD_KEY = 'artists';
export const ARTIST_FILES_RECORD_KEY = 'artist_files';
export interface ARTIST {
    advance_img: string;
    org_id?: string;
    archived?: boolean;
    assigned_to: string[];
    createdAt: Date;
    logo: string;
    name: string;
    photo: string;
    uid: string;
    website: string;
}
