import { ArtistActions, ArtistsActionTypes } from '../actions/artists.actions';

export interface State {
  name: string;
  createdAt: Date;
  photo: string;
  uid: string;
  website: string;
  members: {
    [memberKey: string]: {
      name: string;
      role: string;
      uid: string;
    };
  };
}

export const initialState: State[] = [];

export function reducer(state = initialState, action: ArtistActions): State[] {
  switch (action.type) {
    case ArtistsActionTypes.LoadArtists:
      return [...action.payload];
    default:
      return state;
  }
}
