import { Action } from '@ngrx/store';

export enum ShowProfilesActionTypes {
    LoadShowProfiles = '[ShowProfiles] Load Show Profiles',
}

export class LoadShowProfiles implements Action {
    readonly type = ShowProfilesActionTypes.LoadShowProfiles;
    constructor(public payload: any) {}
}

export type ShowProfilesActions = LoadShowProfiles;
