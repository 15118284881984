import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import 'firebase/storage';
import { EVENTS_RECORD_KEY } from '@tminc/shared/data-models';
import uniqBy from 'lodash-es/uniqBy';
import { AdvanceCompletedPayload, EmailDataType } from './advance-email.model';
import { advanceCompletedEmailFormatter, advanceEmailFormatter } from './advance-email.utils';

@Injectable({
    providedIn: 'root',
})
export class AdvanceEmailService {
    constructor(private afFunc: AngularFireFunctions, private snackBar: MatSnackBar, private db: AngularFirestore) {}

    sendAdvanceEmail(data: EmailDataType) {
        this.checkEventId(data);
        const formattedData = advanceEmailFormatter(data);
        const callable = this.afFunc.httpsCallable('advanceEmail');
        this.snackBar.open(`Sending Your Advance Email...`, 'Ok');
        callable(formattedData).subscribe((response) => {
            console.log(response);
            if (response.success) {
                this.snackBar.open(`"${formattedData.subject}" Email Sent!`, 'Ok');
                this.db.collection(EVENTS_RECORD_KEY).doc(data.eventId).set(
                    {
                        advance_email_sent: firebase.firestore.FieldValue.serverTimestamp(),
                    },
                    { merge: true },
                );
            }
        });
    }
    sendVenueAdvanceEmail(data: EmailDataType) {
        this.checkEventId(data);
        const formattedData = advanceEmailFormatter(data);
        const callable = this.afFunc.httpsCallable('advanceEmail');
        this.snackBar.open(`Sending Your Advance Email...`, 'Ok');
        callable(formattedData).subscribe((response) => {
            console.log(response);
            if (response.success) {
                this.snackBar.open(`"${formattedData.subject}" Email Sent!`, 'Ok');
                this.db.collection(EVENTS_RECORD_KEY).doc(data.eventId).set(
                    {
                        venue_advance_email_sent: firebase.firestore.FieldValue.serverTimestamp(),
                    },
                    { merge: true },
                );
            }
        });
    }

    sendTestEmail(data: EmailDataType) {
        this.checkEventId(data);
        const formattedData = advanceEmailFormatter(data);
        delete formattedData.cc;
        formattedData.to = data.test_email;
        const callable = this.afFunc.httpsCallable('advanceEmail');
        this.snackBar.open(`Sending Test Email...`, 'Ok');
        callable(formattedData).subscribe((response) => {
            console.log(response);
            if (response.success) {
                this.snackBar.open(`Test Email: "${formattedData.subject}" Sent to ${formattedData.to}!`, 'Ok');
            }
        });
    }

    sendAdvanceCompletedEmail(data: AdvanceCompletedPayload) {
        this.checkEventId(data);
        const formattedData = advanceCompletedEmailFormatter(data);
        const callable = this.afFunc.httpsCallable('sendEmail');
        this.snackBar.open(
            `${formattedData?.subject} Sent to ${formattedData?.to?.map((to) => to.email).join(', ')}`,
            'Ok',
        );
        callable(formattedData).subscribe((response) => {
            if (response.success) {
                this.db
                    .collection(EVENTS_RECORD_KEY)
                    .doc(data.eventId)
                    .set(
                        {
                            [`${
                                formattedData?.venue_email ? 'venue_' : ''
                            }completed_email_sent`]: firebase.firestore.FieldValue.serverTimestamp(),
                        },
                        { merge: true },
                    );
            } else {
                this.snackBar.open(`${JSON.stringify(response)}`, 'Ok');
            }
        });
    }

    private checkEventId(data: EmailDataType | AdvanceCompletedPayload) {
        if (!data.eventId) {
            alert('System Error: Missing event id, cannont send email. Contact tech support');
            throw new Error('Missing Event ID');
        }
    }
}
