import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import { LoadContacts } from '@rootStore/actions';
import { UserRoles } from '@tminc/data-access-user';
import { User, VENUE, VENUES_RECORD_KEY, VENUE_CONTACTS_RECORD_KEY } from '@tminc/shared/data-models';
import isEqual from 'lodash-es/isEqual';
import isEmpty from 'lodash-es/isEmpty';
import { of } from 'rxjs';
import { distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { VenuesService } from './venues.service';

@Injectable({
    providedIn: 'root',
})
export class ContactsService {
    constructor(
        private db: AngularFirestore,
        private auth: AngularFireAuth,
        private store: Store<any>,
        private venuesService: VenuesService,
    ) {}

    updateData(user: User) {
        this.auth.authState
            .pipe(
                switchMap((authData) => {
                    const tm_contacts$ = of([]);

                    const venue_contacts$ = this.venuesService.selectedVenue$.pipe(
                        filter((venue) => !isEmpty(venue)),
                        switchMap((venue: VENUE) => {
                            return this.db
                                .collection(`${VENUES_RECORD_KEY}/${venue.place_id}/${VENUE_CONTACTS_RECORD_KEY}`)
                                .valueChanges();
                        }),
                    );

                    const advances$ = user.roles.includes(UserRoles.Promoter) ? venue_contacts$ : tm_contacts$;
                    return authData?.email?.length ? advances$ : of([]);
                }),
                distinctUntilChanged((curr, prev) => isEqual(curr, prev)),
            )
            .subscribe((data) => {
                this.store.dispatch(new LoadContacts(data));
            });
    }
}
