export const BuyerListOptions = ['Third Party', 'Venue/Promoter', 'Artist'].map((v) => ({
    value: v,
    label: v,
}));

export const PrePostShowOptions = ['Pre-Show', 'Post-Show', 'Both'].map((v) => ({
    value: v,
    label: v,
}));

export const DistributedByOptions = ['Tour', 'Venue'].map((v) => ({
    value: v,
    label: v,
}));
