export const YES_NO_OPTIONS = ['YES', 'NO'].map((v) => ({
    value: v,
    label: v,
}));

export const CredentialsOptions = ['Tour Provides', 'Venue Provides'].map(
    (v) => ({
        value: v,
        label: v,
    })
);
export const TourVenueOptions = ['Tour', 'Venue'].map((v) => ({
    value: v,
    label: v,
}));
