import { createSelector, createFeatureSelector } from '@ngrx/store';
import { EVENT } from '@tminc/shared/data-models';

import * as fromEvents from '../reducers/events.reducer';
import { selectShowProfiles } from './show-profiles.selectors';
import find from 'lodash-es/find';
import sortBy from 'lodash-es/sortBy';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import omit from 'lodash-es/omit';

import * as fromPromoterAdvances from '../reducers/promoter-advances.reducer';
import { MeetGreetSchedules } from '@formly/meet-greet/meet-greet.model';
import { ScheduleDOS } from '@formly/schedule/schedule.model';
import { PersonnelTourParty } from '@formly/contacts/contacts.model';

export const selectEventsState = createFeatureSelector<EVENT[]>('events');

const selectArtistsState = createFeatureSelector<fromEvents.State>('artists');

const selectArtistById = (artistId) =>
    createSelector(selectArtistsState, (artists) => {
        return find(artists, ['uid', artistId]);
    });

const selectPromoterAdvancesState = createFeatureSelector<fromPromoterAdvances.State>('promoter_advances');

export const selectEventsOfTour = (tourId) =>
    createSelector(selectEventsState, (events: any) => {
        return events.filter((event) => event.tourId === tourId);
    });

export const selectEventsByArtist = (artistId) =>
    createSelector(selectEventsState, selectShowProfiles(artistId), (events: any, artistTemplates) => {
        const filteredEvents = events.filter((tour) => tour.artistId === artistId);
        const sortedEvents = sortBy(filteredEvents, ['date']);
        return sortedEvents.map((event) => {
            let showProfile = '';
            if (!isEmpty(artistTemplates)) {
                const filteredTemplates = artistTemplates.filter((template) => template.uid === event.template);

                showProfile = get(filteredTemplates[0], 'type');
            }
            return {
                ...event,
                showProfile,
            };
        });
    });

export const selectEventsTableInfoByArtist = (artistId) =>
    createSelector(
        selectPromoterAdvancesState,
        selectEventsState,
        selectShowProfiles(artistId),
        selectArtistById(artistId),
        (promoterAdvances: any, events: any, artistTemplates, artist) => {
            const filteredEvents = events
                .filter((tour) => tour.artistId === artistId)
                .map((event) => {
                    let advance = find(promoterAdvances, ['eventId', event.uid]) || {};
                    // BC it overwrites the event uid
                    advance = omit(advance, ['uid']);

                    return {
                        ...event,
                        ...advance,
                        advance,
                        artist_name: artist?.name,
                        artist_logo: artist?.logo,
                        artist_photo: artist?.photo,
                    };
                });
            const sortedEvents = sortBy(filteredEvents, ['date']);
            return sortedEvents.map((event) => {
                let additionalProps = {};

                if (!isEmpty(artistTemplates)) {
                    const filteredTemplates = artistTemplates.filter((template) => template.uid === event.template);
                    const showProfile = filteredTemplates[0];
                    if (showProfile) {
                        additionalProps = {
                            template: showProfile.uid,
                            showProfile: showProfile.type,
                            show_profile_tm_notes: showProfile?.tm_notes,
                            [PersonnelTourParty]: showProfile[PersonnelTourParty],
                            [ScheduleDOS]: showProfile[ScheduleDOS],
                            tour_support: showProfile?.tour_support,
                            pages: showProfile?.pages,
                            artist_advance_img: showProfile?.advance_img,
                            hospitality_rider: showProfile.hospitality_rider,
                            [MeetGreetSchedules]: event?.advance[MeetGreetSchedules] || showProfile[MeetGreetSchedules],
                        };
                    }
                }
                return {
                    ...event,
                    ...additionalProps,
                };
            });
        },
    );
export const selectEventsTableInfoByArtistAndTour = (artistId, tourId) =>
    createSelector(selectEventsTableInfoByArtist(artistId), (events: any) => {
        return events.filter((event) => {
            return event.tourId === tourId;
        });
    });

export const selectEventsByVenuePlaceId = (place_id: string) =>
    createSelector(selectEventsState, (events: EVENT[]) => {
        return events.filter((event) => {
            return event.venue_id === place_id;
        });
    });
