import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ARTISTS_RECORD_KEY } from '@tminc/shared/data-models';
import { Store } from '@ngrx/store';

import { LoadArtists } from '@rootStore/actions/artists.actions';
import { trace } from '@angular/fire/performance';
import { AngularFireAuth } from '@angular/fire/auth';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ArtistsService {
    constructor(private db: AngularFirestore, private auth: AngularFireAuth, private store: Store<any>) {}

    updateData(userId) {
        this.auth.authState
            .pipe(
                switchMap((authData) => {
                    return authData
                        ? this.db
                              .collection(ARTISTS_RECORD_KEY, (ref) =>
                                  ref.where('assigned_to', 'array-contains', userId),
                              )
                              .valueChanges()
                        : of([]);
                }),
                trace('get_artists'),
            )
            .subscribe((data) => {
                this.store.dispatch(new LoadArtists(data));
            });
    }
}
