import { InjectionToken } from '@angular/core';

interface UserRoleTypes {
    TM: string;
    Manager: string;
    Admin: string;
    Promoter: string;
    Venue: string;
}

export const UserRoles: UserRoleTypes = {
    TM: 'TM',
    Manager: 'Manager',
    Admin: 'Admin',
    Promoter: 'Promoter',
    Venue: 'Venue',
};

export const UserRolesNoAdmin = Object.values(UserRoles).filter((role: string) => role !== UserRoles.Admin);

export const USER_ROLES = new InjectionToken<UserRoleTypes>('User Roles', {
    providedIn: 'root',
    factory: () => UserRoles,
});
