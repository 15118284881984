export const removeUndefinedValues = (data: any) => {
    for (const key in data) {
        if (data[key] === undefined) {
            delete data[key];
        }
    }
    return {
        ...data,
    };
};

export const GetPromoterAdvanceId = (artistId, eventId, data?: any) => {
    if (data?.temp_user_uid || data?.tm_email) {
        return eventId;
    } else if (artistId && eventId) {
        return `${artistId}_${eventId}`;
    } else {
        throw new Error('Invalid artistId or eventId');
    }
};
