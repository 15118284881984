export const EVENTS_RECORD_KEY = 'events';

export interface EVENT {
    org_id?: string;
    archived?: boolean;
    artist_name?: string;
    assigned_to?: string[];
    artistId?: string;
    city: string;
    createdAt?: Date;
    date: any;
    name: string;
    notes?: string;
    promoterContact?: string;
    productionEmail?: string;
    productionContact?: string;
    promoterEmail?: string;
    state: string;
    template: string;
    tourId?: string;
    tm_email?: string;
    uid: string;
    venue: string;
    venue_id: string;
    venue_address: string;
    venue_photo?: string;
    venue_profile_id?: string;
    venue_assigned_to?: string[];
    guestlist_approved?: boolean;
}
