import { FormlyFieldConfig } from '@ngx-formly/core';
import { formlyRow, formlyInput, formlySelect, formlyAutocomplete, AutocompleteFilter } from '../formly-helpers';
import { ContactConfig, ContactsConfig, PersonnelType } from './contacts.interfaces';
import { getContactSelectOptions } from './contact-select-options';
import { FormlyAutocompleteType } from '@formly/common/autocomplete';
import { of } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { VenueContactType } from '@tminc/shared/data-models';

export const PersonnelTourParty = 'tour_personnel_contacts';
export const VenuePersonnelContactsKey = 'venue_personnel_contacts';

export interface ContactType {
    name: string;
    type: string;
    title?: string;
    phone?: string;
    email?: string;
    contact?: string[];
}

export const Contact = (contactConfig: ContactConfig): FormlyFieldConfig[] => {
    return [
        formlyRow([
            formlyInput({
                key: 'name',
                templateOptions: {
                    required: true,
                    ...contactConfig.templateOptions,
                },
            }),
            formlySelect({
                key: 'type',
                templateOptions: {
                    required: true,
                    options: getContactSelectOptions(contactConfig.personnelType),
                    ...contactConfig.templateOptions,
                },
            }),
            formlyInput({
                key: 'title',
                templateOptions: {
                    ...contactConfig.templateOptions,
                },
            }),
        ]),
        formlyRow([
            formlyInput({
                key: 'phone',
            }),
            formlyInput({
                key: 'email',
                validators: {
                    validation: ['email'],
                },
            }),
        ]),
        formlyRow([
            formlySelect({
                key: 'contact',
                templateOptions: {
                    options: getContactSelectOptions(PersonnelType.TOUR_CONACT),
                    label: 'Assign As Contact For...',
                    description: 'Assign This Contact To Specific Advance Page(s). Select All That Apply.',
                    selectAllOption: 'Select All',
                    multiple: true,
                    ...contactConfig.templateOptions,
                },
            }),
        ]),
    ];
};

export const FormlyContactsType = 'contacts';

export const Contacts = (config: ContactsConfig): FormlyFieldConfig[] => {
    return [
        {
            key: config.key,
            type: FormlyContactsType,
            templateOptions: {
                addText: config.addText,
            },
            fieldArray: {
                fieldGroup: [...Contact(config.contactConfig)],
            },
        },
    ];
};

export const VenueContact = (contactConfig: ContactConfig, className): FormlyFieldConfig[] => {
    return [
        formlyRow([
            formlyAutocomplete({
                key: 'name',
                type: FormlyAutocompleteType,
                templateOptions: {
                    required: true,
                    filter: (term) => {
                        const names = contactConfig.contacts_list.map((contact) => contact.name);
                        return of(term ? AutocompleteFilter(names, term) : names.slice());
                    },
                },
                hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                        const control = field.form?.get('name');
                        return control?.valueChanges.pipe(
                            filter((name) => name?.length),
                            tap((name: VenueContactType) => {
                                const contact = contactConfig.contacts_list.find((contact) => contact.name === name);
                                const contact_keys = ['email', 'type', 'phone'];
                                if (contact?.name?.length) {
                                    contact_keys.forEach(
                                        (key) => contact[key] && field.form?.get(key)?.setValue(contact[key]),
                                    );
                                }
                                // else {
                                //     contact_keys.forEach((key) => field.form?.get(key)?.setValue(null));
                                // }
                            }),
                        );
                    },
                },
            }),
            formlySelect({
                className,
                key: 'type',
                templateOptions: {
                    options: getContactSelectOptions(contactConfig.personnelType),
                    ...contactConfig.templateOptions,
                    required: true,
                },
            }),
            formlyInput({
                className,
                key: 'title',
                templateOptions: {
                    ...contactConfig.templateOptions,
                },
            }),
        ]),
        formlyRow([
            formlyInput({
                className,
                key: 'phone',
            }),
            formlyInput({
                className,
                key: 'email',
                validators: {
                    validation: ['email'],
                },
            }),
        ]),
        formlySelect({
            key: 'contact',
            templateOptions: {
                required: true,
                options: getContactSelectOptions(contactConfig.personnelContact),
                label: 'Assign As Contact For...',
                description: 'Assign This Contact To Specific Advance Page(s). Select All That Apply.',
                multiple: true,
                selectAllOption: 'Select All',
                ...contactConfig.templateOptions,
            },
        }),
    ];
};
export const VenueContactFields = (config: ContactsConfig, className = ''): FormlyFieldConfig[] => {
    return [
        {
            key: config.key,
            type: FormlyContactsType,
            templateOptions: {
                addText: config.addText,
            },
            fieldArray: {
                fieldGroup: [...VenueContact(config.contactConfig, className)],
            },
        },
    ];
};
