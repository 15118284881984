import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import get from 'lodash-es/get';
import { map, take, tap } from 'rxjs/operators';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from '@angular/router';
@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private afAuth: AngularFireAuth, private router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.afAuth.authState.pipe(
            take(1),
            map((authData) => {
                return get(authData, 'uid', false) ? true : false;
            }),
            tap((allowed: boolean) => {
                if (!allowed) {
                    this.router.navigate(['']);
                }
            })
        );
    }
}
