import { Action } from '@ngrx/store';

export enum VenuesActionTypes {
    LoadVenues = '[Venues] Load Venues',
}

export class LoadVenues implements Action {
    readonly type = VenuesActionTypes.LoadVenues;
    constructor(public payload: any) {}
}

export type VenuesActions = LoadVenues;
