import { Action } from '@ngrx/store';

export enum VenueProfilesActionTypes {
    LoadVenueProfiles = '[VenueProfiles] Load Venue Profiles',
}

export class LoadVenueProfiles implements Action {
    readonly type = VenueProfilesActionTypes.LoadVenueProfiles;
    constructor(public payload: any) {}
}

export type VenueProfilesActions = LoadVenueProfiles;
