import { AdvancePageActionTypes, AdvancePageActions } from '../actions/advance-page.actions';

interface UserRecordType {
    [key: string]: any;
}
export interface State {
    user_record: UserRecordType | null;
    firebase_user: UserRecordType | null;
}

export const initialState: State = {
    user_record: null,
    firebase_user: null,
};

export function reducer(state = initialState, action: AdvancePageActions): State {
    switch (action.type) {
        case AdvancePageActionTypes.SetUserType:
            return {
                ...state,
                ...action.payload,
            };
        case AdvancePageActionTypes.SetUserRecord:
            return {
                ...state,
                user_record: {
                    ...action.payload,
                },
            };
        case AdvancePageActionTypes.SetFirebaseUser:
            return {
                ...state,
                firebase_user: {
                    ...action.payload,
                },
            };
        default:
            return state;
    }
}
