export const GUESTLIST_RECORD_KEY = 'guestlist';

export enum GUEST_STATUS {
    Pending = 'pending',
    Approved = 'approved',
    Denied = 'denied',
}

export const GUEST_STATUS_ARRAY = Object.values(GUEST_STATUS);

export enum GUEST_ACCESS_LEVEL {
    ALL_ACCESS = 'all access',
    GUEST = 'guest',
    MEET_GREET = 'meet & greet',
    PHOTO = 'photo',
    STICKIE = 'stickie',
    VIP = 'vip',
}

export const GUEST_ACCESS_LEVEL_ARRAY = Object.values(GUEST_ACCESS_LEVEL);

export interface GUEST {
    first_name: string;
    last_name: string;
    email: string;
    quantity: number;
    requested_by: string;
    affiliations: string;
    access_level: GUEST_ACCESS_LEVEL;
    status: GUEST_STATUS;
    uid: string;
}
