import { Action } from '@ngrx/store';

export enum ArtistsActionTypes {
  LoadArtists = '[Artists] Load Artists'
}

export class LoadArtists implements Action {
  readonly type = ArtistsActionTypes.LoadArtists;
  constructor(public payload: any) {}
}

export type ArtistActions = LoadArtists;
