import { FormlyFieldConfig } from '@ngx-formly/core';
import { formlyInput, formlyRow, formlyCheckbox, formlyConfig, TextArea } from '../formly-helpers';

export const FormlyScheduleType = 'schedule';
export const ScheduleDOS = `${FormlyScheduleType}_dos`;
export const VenueScheduleNotes = `venue_schedule_notes`;

export const ScheduleItem = (disabled: boolean = false): FormlyFieldConfig[] => {
    return [
        formlyRow([
            formlyInput({
                key: 'item',
                templateOptions: {
                    disabled,
                    required: true,
                },
            }),
        ]),
        formlyRow([
            formlyInput({
                key: 'start_time',
                templateOptions: {
                    type: 'time',
                    disabled,
                    required: true,
                },
                // validators: {
                //     validation: ['time'],
                // },
            }),
            formlyInput({
                key: 'end_time',
                templateOptions: {
                    type: 'time',
                    disabled,
                },
                // validators: {
                //     validation: ['time'],
                // },
            }),
            disabled
                ? {}
                : formlyCheckbox({
                      key: 'next_day',
                      templateOptions: {
                          disabled,
                      },
                  }),
        ]),
    ];
};

export const ScheduleFields = (config: { key: string; disabled?: boolean }): FormlyFieldConfig[] => {
    return [
        {
            key: config.key,
            type: FormlyScheduleType,
            templateOptions: {
                addText: config.disabled ? '' : 'Add Schedule',
            },
            fieldArray: {
                fieldGroup: [...ScheduleItem(config.disabled)],
            },
        },
    ];
};

export const ScheduleSpecFields = (config: { key: string }): FormlyFieldConfig[] => {
    return [
        formlyRow([
            TextArea('time_requested_for_load_in', {}, config.key),
            TextArea('time_requested_for_load_out', {}, config.key),
        ]),
        TextArea('time_requested_for_venue_access', {}, config.key),
    ];
};

export const VenueScheduleFields = (config: formlyConfig): FormlyFieldConfig[] => {
    return [
        formlyRow([
            TextArea('show_curfew', {}, config.key, config.className),
            TextArea('venue_curfew', {}, config.key, config.className),
        ]),
        formlyRow([
            TextArea('backstage_curfew', {}, config.key, config.className),
            TextArea('parking_curfew', {}, config.key, config.className),
        ]),
    ];
};
