import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdate } from '@angular/service-worker';
import { SoftwareUpdateDialogComponent } from '@common/components/sofware-update-dialog/software-update-dialog.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent {
    constructor(private swUpdate: SwUpdate, private dialog: MatDialog) {
        this.swUpdate.available.subscribe(() => {
            const dialogRef = this.dialog.open(SoftwareUpdateDialogComponent).updatePosition({ top: '50px' });

            dialogRef.afterClosed().subscribe(() => {
                window.location.reload();
            });
        });
    }
}
