export const BACK_OFFICE_EVENTS_RECORD_KEY = 'back_office_events';

export enum VENUE_BACK_OFFICE_EVENT_TYPE {
    DEADLINE = 'Deadline',
    DELIVERY = 'Delivery',
    MEETING = 'Meeting',
    PRODUCTION = 'Production',
    RECEIVED = 'Received',
    REMINDER = 'Reminder',
    SUBMITTED = 'Submitted',
    WALK_THRU = 'Walk-Thru',
}
export enum TOUR_BACK_OFFICE_EVENT_TYPE {
    DEADLINE = 'Deadline',
    REMINDER = 'Reminder',
}

export interface BACK_OFFICE_EVENT {
    contact_name: string;
    contact_phone?: string;
    contact_email?: string;
    event_type: VENUE_BACK_OFFICE_EVENT_TYPE | TOUR_BACK_OFFICE_EVENT_TYPE;
    event_description?: string;
    date: any;
    start_time: string;
    end_time?: string;
    created_at: Date;
    notes?: string;
    uid: string;
    venue_id?: string;
    artist_id?: string;
}
