export const HOLDS_RECORD_KEY = 'holds';

export interface EVENT_HOLD {
    artist: string;
    created_at: Date;
    date: any;
    email: string;
    promoter: boolean;
    uid: string;
    stage: string;
    venue_id: string;
}
