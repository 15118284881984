import { ToursActionTypes, ToursActions } from '../actions/tours.actions';

export interface State {
    createdAt: Date;
    name: string;
    template: string;
    uid: string;
}

export const initialState: State[] = [];

export function reducer(state = initialState, action: ToursActions): State[] {
    switch (action.type) {
        case ToursActionTypes.LoadTours:
            return [...action.payload];
        default:
            return state;
    }
}
