import { stringArrayJoiner } from '@utils/string-formatters';
import { uniqBy } from 'lodash-es';
import { AdvanceCompletedPayload, EmailDataType } from './advance-email.model';

export const contactsStringFormatter = (array) => {
    return (array || [])
        .map((contact) => stringArrayJoiner([contact.name, contact.title, contact.email, contact.phone]))
        .join('\n');
};

export const advanceEmailFormatter = (data: EmailDataType) => {
    const cc = (data.cc || '').split(',').map((cc: string) => ({ email: cc.trim() }));
    const all_cc = ([...cc] || [])
        .map((contact) => contact?.email)
        .filter(Boolean)
        .map((email) => ({ email }));
    return {
        ...data,
        advance_contacts: contactsStringFormatter(data.advance_contacts),
        to: uniqBy(data?.to, 'email'),
        cc: uniqBy(all_cc, 'email'),
    };
};
export const advanceCompletedEmailFormatter = (data: AdvanceCompletedPayload) => {
    const cc = (data.cc || '').split(',').map((cc: string) => ({ email: cc.trim() }));
    const all_cc = ([...cc] || [])
        .map((contact) => contact?.email)
        .filter(Boolean)
        .map((email) => ({ email }));
    return {
        ...data,
        to: uniqBy(data?.to, 'email'),
        cc: uniqBy(all_cc, 'email'),
    };
};
